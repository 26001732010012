export default 

{
  dashboard: {
    available_balance: 'Доступный баланс',
    dost_pribyl: 'Доступная прибыль',
    account: 'программа доходности',
    valuta: '$',
    pribil_segodnya: 'Прибыль сегодня',
    add_cashback: 'Получено прибыли в этом месяце',
    wait_cashback: 'Прибыль все еще ожидает зачисления.',
    sum_cashback: 'Сумма прибыли, который можно зафиксировать, составляет',
    new_cashback: 'с вашего депозита вы накопили прибыль, но на зачисление ее не хватает депозита. Пополните',
    implement_cashback: 'чтобы реализовать ВСЮ прибыль',
    withdrawal: 'Вывод',
    date: 'дата',
    settings: 'Мои данные',
    total_deposit: 'Депозит',
    withdrawn: 'Выведено',
    active_deposits: 'Все депозиты',
    Top_up_dep: 'Пополнить',
    transfer: 'Перевод',
    invest_plan: 'Инвест программы',
    team_profit: 'Начислено c реф-ки',
    accrued_cashback: 'Начисленная прибыль от друзей',
    accrued_market: 'Начислено с Маркетплейсов',
    accrued_go: 'Начисление на GO Pool начнется 1 марта',
    fin_plan: 'Мой финансовый план прибыли',
    pribil_plan: 'План прибыли, даты реактивации депозита, фактическая прибыль',
    open_table: 'Открыть таблицу',
    active_dep: 'Активные депозиты',
    profit_dep: 'Прибыль по этому депозиту не будет начисляться в следующем расчетном периоде, вы можете активировать его снова, нажав «Включить»',
    loading: 'Загрузка...',
    empty_deps: {
      '0':'пока нет депозита, пополните счет для получения прибыли',
      '1':'пополнить'
    },
    work_dep: 'Рабочий депозит, на нем начисляется прибыль',
    off_dep: 'Отключить депозит',
    on_dep: 'Включить',
    send_schet: 'Отправить на счет',
    in_progress: 'в процессе зачисления',
    new_cicl: 'новый цикл начинается через',
    sum_day: 'дней',
    new_period: 'новый период начнется завтра',
    lichniy_kabinet: 'Личный кабинет',
    deposit_active: { 
      '0':'Депозинт на',
      '1':'активен',
      '2':'Внимание! Эффективность этого депозита снижена до',
      '3':'Внимание! Эффективность этого депозита УВЕЛИЧЕНА до',
      '4':'относительно заявленного',
    },
    support: 'Свяжитесь с поддержкой для восстановления работы',
    remaining_days: 'осталось до конца инвест-периода ',
    buttons: {
      stop:'Остановить',
      rise_profit:'Увеличить доходность'
    },
    close_dep_info:'Вы хотите отключить депозит?',
    earn_more:{
      '0':'Вы можете заработать больше ',
      '1':'Прибыль по этому депозиту за ',
      '2':'дней составила бы от ',
      '3':'до ',
      '4':'от ',
      '5':'на сумму',
      '6': 'чистой прибыли, не менее',
      '7':'в случае, если этот депозит работал бы на программе "Факторинга" (тариф "3 МЕСЯЦА"). Сейчас Вам доступно – улучшить показали этого депозита.',
    },
    close_in_advance:'Закрывайте депозит заранее до начала нового расчетного периода.  Закрытый депозит перейдет в доступную прибыль после истечения срока блокировки',
    confirm_action: 'Вы подверждаете свое действие?',
    withdraw_now: 'Вывести депозит сейчас?',
    
  },
  topup:{
    safe_p2p: 'Безопасная P2P сделка',
    topup_card: 'Пополнить картой',
    topup_dep: 'Пополнить депозит с комиссией от 0.9% до 1.8%, зачисление до 30 минут (сбер, альфа, тинькоф, озон, сбп и др)',
    choose: 'Выбрать',
    topup_crypto: 'Пополнить криптовалютой',
    topup_moment: 'Моментальное пополнение криптовалютой USDT, ETH, BNB, TRX без комиссии в любой cети',
    topup_birja: 'Пополнить на бирже MetaS',
    topup_vvod: 'Пополнение и вывод',
    topup_nocom: 'без комиссии',
    other_country: 'Казахстан, БЕЛАРУСЬ, Россия, Молдова, Киргизия, Таджикистан, Узбекистан, Турция и др',
    skoro: 'скоро...'

  },
  history: {
    title: 'История начислений',
    desc: 'Тут показаны все начисления по вашим депозитам, а также начисления от друзей и друзей друзей',
    tt: 'Начисления',
    success: 'Успешно',
    p_purpose: 'Назначение',
    curr_account: 'Текущий счет',
    status: 'Статус',
  },

  withdrawn: {
    all_history: 'Смотреть всю историю',
    my_schet: 'Мои счета',
    raspred_pribil: 'Распределение прибыли',
    account_balance: 'На счету в работе',
    valuta: '$',
    in_reinvest: 'зачисление реинвест',
    dostupno: 'Доступно:',
    reinvest: ' РЕИНВЕСТИРОВАТЬ',
    last_profit: 'Последние начисления',
    loading: 'Loading',
    obrabotka: 'идет обработка',
    vivesti: 'Вывести',
    checkagain: 'Проверьте данные вывода, возможно сумма + комиссия превышают доступный баланс',
    greencomm:'У вас зеленая галочка, комиссия за ввод и вывод (кроме TRC) – 0%',
    download: 'Загрузка',
    poisk_schetov: 'поиск счетов ...',
    zayavki: 'Ожидает обработки',
    no_zayavok: 'Новых заявок нет',
    suc_trans: 'Движения по счету',
    otsut_trans: 'Транзакции на счете отсутвуют',
    vivod: 'вывод',
    vvod: 'ввод',
    reinvesti: '(реинвест)',
    deposit: 'Депозит счета:',
    vivod_sredstv: 'Вывод средств',
    vibor_schet: 'Выберите счет для вывода',
    dostupno_ot: 'доступно для вывода от',
    do: 'до',
    na_etom_schete: 'на этом счете нет доступных средств для вывода или сумма меньше 100 Р',
    metod: 'Выберите метод перевода',
    card: 'По номеру карты',
    spb: 'СБП',
    spb_add: '(Номер телефона)',
    crypto: 'Крипто',
    plata: 'Укажите платежные данные ',
    get_crypto: 'Выберите криптовалюту',
    network: 'Выберите сеть для перевода',
    commission: 'Комиссия:',
    sum: 'Введите сумму для расчета комиссии',
    bank: "Банк",
    fio: "Имя Фамилия",
    number: "Номер телефона",
    nazv_bank: "Название банка",
    name_owmner: "Имя владельца",
    number_card: "Номер карты",
    numberwallet: "Номер кошелька",
    sum_withdrow: "Сумма на вывод",
    vvedite: 'Введите корректное название Банка получателя',
    check_sum: 'Проверьте сумму вывода',
    check_dannie: 'Проверьте данные о владельце карты',
    check_phone: 'введите корректный номер телефона для перевода',
    check_number: 'введите корректный номер карты',
    check_wallet: 'введите корректный номер кошелька',
    info_sum:'Сумма превышает допустимое значение',
    warning1: 'Внимание! Во избежании банковских блокировок (фз 115) рекомендуемая сумма вывода не должна превышать 50 000 рублей в день. Пожалуйста, разделите сумму транзакции на несколько частей, воспользуйтесь криптовалютой, или свяжитесь с Вашим менеджером.',
    not_max: 'Сумма на вывод не может быть меньше 100 р',
    withd_request_succ: 'Транзакция отправлена. Если вывод появился в списке на обработку, это значит, что все прошло успешно: запрос отправлен и находится на проверке. Обработка занимает несколько часов, и по регламенту (до 24 часов) деньги будут отправлены на указанный адрес. ',
    withdrawal_request: 'Запрос на вывод',
    reglament:'Внимание! Вывод занимает (по регламенту)  до 24 часа. Реальное время вывода зависит от метода оплаты, статуса аккаунта и суммы транзакции –  чаще всего вывод происходит в течение 2-3 часов.',
    new_req_soon: "Заявку на вывод вы можете подать 1 раз в сутки. Новую заявку на вывод вы можете создать  через",
    date: 'дата',
    close: 'Закрыть',
    
  
  },
  tasks: {
    update: 'обновление ежедневно',
    pereshet: 'внимание пересчет происходит 1 раз в сутки',
    wait_zachislenie: 'ожидает зачисления',
    valuta: '$',
    svodka: 'Сводка',
    friend: 'Кол-во пользователей',
    chelovek: 'чл.',
    my_cashback: 'Моя прибыль',
    earned_cashback: 'Заработано с прибыли пользователей',
    see_statis: 'Посмотреть статистику',
    stat_pozhe: 'Структура пользователей появится позже',
    partner: 'Партнерская программа',
    esli_vi: 'Если вы приглашаете друзей, вы не только',
    povish_cashback: 'получаете повышенную прибыль',
    no_i: 'но и',
    procent: 'проценты',
    withcashback: 'с прибыли ваших',
    friend_and_friend: 'друзей и друзей ваших друзей.',
    promocode: 'Промокод',
    qrcode: 'Или покажите индивидуальный  QR-код для скачивания',
    ref_ssilka: 'Или воспользуйтесь реферальной ссылкой по которой пользователь сможет подключиться к проекту',
    registration: 'Внимание! Регистрация по Вашему промокоду дает пользователю дополнительный профит к прибыли в размере 1% в конце каждого месяца',
    how_work: 'Как это работает?',
    max_cashback: 'Какой максимальную прибыль я могу получить?',
    add_cashback: 'Когда вы только зарегистрируетесь, ваша прибыль будет составлять 22%,  можно повысить его до 35% с чека.',
    do_doing: 'Что нужно делать?',
    plus_cash: 'Чтобы повысить прибыль, вам нужно просто приглашать своих друзей. Каждый приглашенный друг повышает вашу прибыль. "Мультиаккаунты" и связанные аккаунты запрещены. То есть вам нужно пригласить 25 человек, чтобы ваш прибылб стала 35%.',
    obnovlenie_struk: 'Как часто обновляются данные по структуре?',
    one_day: 'Один раз в сутки, первое обновление начисляет профит за предыдущий день, а второе (на следующий день) зачисляет его на счет для вывода, после цикл повторяется',
    s_obema: 'Буду ли я получать прибыль с объема?',
    big_plus: 'Но есть еще огромный плюс: если вы приглашаете друзей, вы не только получаете повышенную прибыль, но и проценты с депозитов ваших друзей и друзей ваших друзей.',
    ref_schet_val: 'На реферальном счету есть деньги, но они не переходят на счет Telos Market для вывода',
    ot_100: 'Перевод денег на счет доступный к выводу осуществляется по КАЖДОМУ уровню при достижении суммы от 100 рублей',
    
    partner_program: 'Партнерская программа',
    desc_p:'Если вы приглашаете друзей, вы не только получаете повышенную прибыль, но и проценты с прибыли ваших друзей и друзей ваших друзей. ',
    read_det: 'Подробнее',
    invite: 'Пригласить',
    link_for_web: 'Ссылка для веб-приглашения',
    in_telegram: 'Внутри телеграм',
    code:'Код',
    link_for_tg: 'Ссылка для телеграм',
    for_download: 'для скачивания',
    show_individual:'Или покажите индивидуальный',
    promo_code: 'Промокод',
    link: 'Ссылка',
    desk_info_ref_link: {
      '0':'Или воспользуйтесь',
      '1': 'реферальной ссылкой',
      '2': 'по которой пользователь сможет подключиться к проекту.'
    },
    stat: 'Статистика',
    clicks_tg: 'Переходы в телеграм Бот',
    total_users: 'Количество пользователей в команде',
    volume_team: 'Объем структуры',
    profit_from_team: 'Моя прибыль от прироста объема команды ',
    profit_from_team_passive: 'Пассивный доход с дохода команды',
    upgrade_self_portfolio: 'Апгрейд эффективности моего портфеля',
    month: 'месяц',
    total_m: 'Итого денег',
  },
  statref: {
    vsego: 'Всего:',
    chel: 'чел.',
    obem: 'Объем:',
    obem1: 'Объем',
    valuta: '$',
    valuta1: 'руб',
    statist: 'статистика обновлена ',
    menee_chasa: 'менее часа',
    nazad: 'назад',
    update_dannih: 'данные обновляются один раз в сутки',
    level: 'Уровень',
    earned_obshii: 'Обший заработок:',
    send_na_schet: 'Отправлено на счет:',
    ottok: 'Отток за 10 дней:',
    wait_zachislenia: 'Ожидает зачисления',
    hour: 'ч.',
    deposit: 'Депозит:',
    vivel: 'Вывел за последние 10 дней:',
    plan_vivod: 'Планирует вывод: ',
    reinvest: 'Реинвест:',
    date: 'Дата: ',
    proglasil: 'Пригласил',
    loading: 'Loading...',
    zagruzka: 'загрузка данных ...',
    obiem: 'Объем структуры:',
    razmer: 'Размер структуры: ',
    chelovek: 'человека',
    copy_buffer: 'Скопировано в буфер',
    no_copy: 'не скопировано',
    actual_data: 'загружены актуальные данные',
    dohoddohod: 'Доход с дохода',
    empty_state:{
      '0': 'Пользователй на уровне ',
      '1': 'пока нет. Мы будем следить за обновлениями и сообщим вам, когда они появятся.'
    }
    

  },
    profile: {
      promocode: 'Промокод:',
      manager: 'Личный менеджер',
      promo: 'ПРОМОКОД:',
      telegram: 'Telegram:',
      email: 'Email:',
      new_info: 'Много полезной информации публикуется на канале в tg',
      local_settings: 'Локальные настройки',
      country: 'Страна',
      country_1: 'Россия',
      valuta_slovo: 'Валюта',
      valuta: '$',
      language: 'Язык',
      language_code: 'Ru',
      info_profile: 'Информация профиля',
      finance: 'Финансы',
      otchet: 'Еженедельный отчет',
      security: 'Безопасность аккаунта',
      new_vhod: 'Новый вход',
      new_password: 'Смена пароля',
      safe: 'Безопасность',
      sbrosit: 'Сбросить',
      loading: 'загрузка ...',
      new_pass: 'Сменить пароль',    
      st_password: 'старый пароль',
      new_password1: 'новый пароль',
      new_password_add: 'новый пароль (еще раз)',
      full_fio: 'Имя и Фамилия',
      number_phone: 'например, 79991112233',
      phone:'Телефон',
      fio: 'Имя',
      compl_save: 'идет сохранение...',
      save: 'Cохранить',
      change_profile_info: 'Изменить инфо-профиля',
      manager_name: 'Ваш личный менеджер',
      

  },
    signup_redirect: {
      new_acc: 'Создать аккаунт:',
      apply: 'Регистрируясь вы подтверждаете что соглашаетесь с',
      pravila: 'Правилами пользования:',
      nashei: 'и нашей:',
      policy: 'Политикой конфиденциальности:',
      complite_reg: 'Выполняется регистрация...',
      login_in: 'Зарегистрироваться',
      after_reg: 'После регистрации, система отправит вас на страницу скачивания приложения, введите теже самыеавторизационные данные аккаунта для входа',

    },
    signup: {
      new_acc: 'Создать аккаунт:',
      pretext: 'Чтобы пройти регистрацию, заполните форму ниже',
      apply: 'Регистрируясь вы подтверждаете что соглашаетесь с',
      pravila: 'Правилами пользования:',
      nashei: 'и нашей:',
      policy: 'Политикой конфиденциальности:',
      complite_reg: 'Выполняется регистрация...',
      login_in: 'Зарегистрироваться',
      load_acc: 'Уже есть аккаунт?',
      vvoiti: 'Войти',
      vashe_imya: 'Ваше имя',
      email: 'Email',
      telephone: 'Telegram / Whatsapp',
      password: 'Пароль',
      add_password: 'Повторите пароль',
      promocode: 'Промокод (необязательно)',
      
    },

    sign_monitor: {
      welcome: 'Добро пожаловать',
      email_pass: 'Введите свой E-mail и Пароль чтобы войти в личный кабинет',
      Email: 'Email',
      parol: 'Пароль',
      remember: 'Запомнить браузер',
      loading: 'Loading...',
      vhod: 'Войти',
      no_acc: 'У Вас нет аккаунта?',
      registration: 'Зарегистрироваться',
      dont_passw: 'Не подходит пароль?',
      restore: 'Восстановить'
    },
    sign_in: {
      welcome: 'Добро пожаловать',
      email_pass: 'Введите свой E-mail и Пароль чтобы войти в личный кабинет',
      email: 'Email или телефон',
      parol: 'Пароль',
      remember: 'Запомнить браузер',
      loading: 'Loading...',
      vhod: 'Войти',
      no_acc: 'У Вас нет аккаунта?',
      registration: 'Зарегистрироваться',
      dont_passw: 'Не подходит пароль?',
      restore: 'Восстановить',
      telegram_auth: {
        0: 'Если вы авторизовались в Телеграм  мессенджере ранее и не помните пароль, ',
        1: 'нажмите на ссылку',
        2: 'в телеграм. Система перенаправит вас в бот для получения логина и пароля'
      }

    },
    restore: {
      restore_pass: 'Восстановление пароля',
      vvod_email: {
        '1': 'Введите ваш адрес электронной почты или номер телефона в соответствующее поле.',
	      '2':'Нажмите кнопку “Восстановить пароль”',
	      '3': 'На указанный адрес электронной почты или номер телефона придет сообщение с инструкциями для восстановления пароля'

      },
      email: 'Email',
      new_pass: 'Отправить новый пароль'

    },
    ordered: {
      no_tovar: 'пустой список  / здесь товаров нет',
      check: 'Чек',
      na: 'на',
      valuta: 'руб',
      cashback: 'прибыль',
      valutas: '$'

    },
    telegram: {
      telegram_app: 'Telegram App',
      use_tg: 'Подключите бот-приложение в Telegram, чтобы безопасно работать с финансами, получать уведомления о начислениях и выводах средств, получать отчеты о работе компании и многое другое',
      podkl: 'Подключить'

    },
    youtube: {
      opublikov: 'Опубликовано',
      day_ago: 'дней назад'

    },

    reinvestirovat: {
      reinvest: 'Реинвестировать',
      with_pribil: 'С прибыли ',
      dostupno: 'доступно ',
      valuta: '$',
      na_deposit: 'На депозит',
      deposit: 'депозит:',
      sum: 'Сумма',
      sum_exp: 'Сумма превышает допустимое значение',
      warning: 'Реинвестирование эквивалентно депозиту без комиссии. Новый реинвест создает отдельную тразакцию в базе, которая не влияет на срок других депозитов, а прибыль по ней начисляется отдельно ',
      reinvest_title: 'Инвестировать с баланса ',
      
    },

    top_up: {
      popolnenie: 'Пополнение',
      schet_popolneniya: 'Выберите счет пополнения',
      minimum: 'минимальное пополнение от ',
      valuta: '$',
      sum: 'Сумма',
      promo: '+ промокод',
      loading: 'Loading...:',
      zagruzka: 'Загружаем ...',
      popolnit: 'Пополнить',
      summa: 'Сумма пополнения',
    },

    topup_crypto: {
      crypto: 'Криптовалюта',
      curr_balance: 'Текущий баланс',
      acc: 'Выберите счет пополнения',
      alternative: 'Через Банк',
      alternative_desc: 'Пополнение счета через банк',
      paymentMetod: 'Выберите метод пополнения',
      popolnenie: 'Пополнение',
      schet_popolneniya: 'Выберите счет пополнения',
      choose_crypto: 'Выберите криптовалюту',
      valuta: '$',
      choose_network: 'Выберите сеть для перевода',
      see_address: 'Показать адрес',
      loading: 'Loading...:',
      warning: 'Внимание! Отправляйте только ',
      address: 'на этот адрес. Убедитесь, что вы совершаете перевод в сети',
      copy: 'Копировать',
      minim_dep: 'Минимальный депозит ',
      usd_zachis: 'USD Зачисление произойдет на счет после 15 подтверждений сети, если что вы всегда можете обратиться в службу технической поддержки. Мы не несем ответственность если вы отправили активы через неверную сеть '
      
    },

    sidenav: {
      main: 'Главная',
      top_up: 'Пополнение',
      withdrawn: 'Мои счета',
      task: 'Команда',
      profile: 'Профиль',
      report: 'Отчеты',
      invest_plan:'Тарифы'
    },
    toast:{
      crypto_address_get: "Персональный адрес вашего кошелька получен",
      unknown_error: "Неизвестная ошибка сообщите в поддержку",
      deposit_status_changed:"Статус депозита изменен",
      new_password_sent: "Новый пароль отправлен на вашу почту",
      check_spam: "Проверьте папку спам",
      success_login: "Успешный вход",
      succsess_reg: "Успешная регистрация",
      profile_info_updated: "Информация профиля обновлена",
      reinvest_request_created: "Запрос на реинвестирование создан",
      open_payment_window: "Открывается окно оплаты",
      auth_befor_pay: "Авторизуйтесь перед оплатой",
      withdraw_req_created: "Запрос на вывод создан",
      request_applied: "Запрос отправлен",
      item_deleted_restart: "Элемент удален, перезагрузите страницу",
      login_to_account: "Войдите в аккаунт",
    },
      invest:{
        investment_programs: 'Инвестиционные программы',
        long_desc: 'Это раздел об инвестициях. Здесь мы публикуем наши инвестиционные программы, которые разработаны с учетом различных концепций заработка. Мы не только стремимся к устойчивому росту, но и к созданию возможностей для вас. В каждой программе мы активно работаем, чтобы заработать, и с радостью делимся с вами частью нашей прибыли. Присоединяйтесь к нам, и пусть ваши инвестиции работают на вас!',
        profit: 'Прибыль',
        profit_calculator: 'Калькулятор прибыли',
        bot_card:{
          limits: 'Лимиты',
          profitability: 'Доходность',
          lock_in: 'Блокировка',
          prof_frieq: 'Частота начисления прибыли',
        },
        dep_amount: 'Сумма депозита',
        investment_dur: 'Срок инвестиции',
        days: 'дней',
      },
      
}

